




































import { Vue, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import AssetsTable from '@/components/assets/AssetsTable.vue';
import { GetCollectionParams } from '@/store/actions';
import { bloqifyFirestore } from '@/boot/firebase';

@Component({
  components: {
    AssetsTable,
  },
})

export default class Assets extends Vue {
  @Action bindFirestoreReference!: Function;
  @Action unbindFirestoreReferences!: Function;

  loadingPromise: Promise<any> = Promise.resolve();

  mounted(): void {
    this.loadingPromise = this.bindFirestoreReference(
      {
        name: 'boundAssets',
        ref: bloqifyFirestore.collection('assets')
          .where('deleted', '==', false)
          .orderBy('name', 'asc'),
      },
    );
  }

  beforeDestroy(): void {
    if (!this.$route.fullPath.includes('assets')) {
      this.unbindFirestoreReferences([
        { name: 'boundAssets' },
      ]);
    }
  }
}
