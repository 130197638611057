
























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { State } from '@/models/State';
import { DataContainerStatus } from '@/models/Common';
import { Asset } from '@/models/assets/Asset';
import { GetCollectionParams } from '@/store/actions';
import { ErrorVertebra } from '@/store/utils/skeleton';
import Modal from '@/components/common/Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class DeleteAssetModal extends Vue {
  @Prop({ default: undefined }) asset!: Asset;

  @Action(addToastMessage) addToastMessage!: Function;
  @Action handleDeleteAssetById!: Function;
  @StateClass('asset') stateAsset!: State['asset'];

  @Watch('stateAsset.status')
  onstateAssetChange(assetStatus: DataContainerStatus): void {
    // Error
    if (assetStatus === DataContainerStatus.Error) {
      this.addToastMessage({
        text: this.stateAsset?.error,
        type: 'danger',
      });
    }

    // Success
    if (assetStatus === DataContainerStatus.Success) {
      // Notification
      this.addToastMessage({
        text: 'The asset has been successfully removed.',
        type: 'success',
      });
    }

    if (assetStatus !== DataContainerStatus.Processing) {
      this.$emit('close');
    }
  }

  get isProcessing(): boolean {
    return this.stateAsset?.status === DataContainerStatus.Processing;
  }
}
